import { Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Chip from '@/components/ui/Chip/Chip';
import useFormatMessage from '@/hooks/useFormatMessage';

import Images from '../Images/Images';
import { imagesPropTypes } from '../propTypes';
import { AvailabilityInfo } from './MainInfo.style';
import Price from './Price/Price';

const MainInfo = ({ name, familyName, price, priceBasis, images, form }) => {
  const formatMessage = useFormatMessage();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={6}>
        <Images images={images} />
      </Grid>
      <Grid item xs={12} md={6}>
        {familyName && (
          <Chip uppercase sx={{ mb: 2 }}>
            {familyName}
          </Chip>
        )}
        <Typography
          variant="h3Redesign"
          component="h1"
          color="primary.dark"
          mb={1.5}
          sx={{ hyphens: 'auto' }}>
          {name}
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="space-between"
          alignItems="baseline"
          spacing={2}>
          <Price price={price} priceBasis={priceBasis} />
          <AvailabilityInfo availableInstantly={!!price}>
            {formatMessage(
              price
                ? 'product_details_page_available_immediately'
                : 'product_details_page_available_at_notice'
            )}
          </AvailabilityInfo>
        </Stack>
        {form}
      </Grid>
    </Grid>
  );
};

MainInfo.propTypes = {
  name: PropTypes.string,
  familyName: PropTypes.string,
  price: PropTypes.number,
  priceBasis: PropTypes.string,
  images: imagesPropTypes,
  form: PropTypes.element,
};

export default MainInfo;
