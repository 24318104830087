const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;

export const setLocalStorageItem = (key, value, options = {}) => {
  // ttl is time to live in milliseconds
  const { shouldExpire, ttl } = options;
  if (shouldExpire) {
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + (ttl ?? THIRTY_DAYS_IN_MS),
    };
    localStorage.setItem(key, JSON.stringify(item));
  } else {
    const valueToStore =
      typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, valueToStore);
  }
};

export const getLocalStorageItem = key => {
  const item = localStorage.getItem(key);
  try {
    const parsedItem = JSON.parse(item);
    const now = new Date();

    if (
      parsedItem &&
      Object.prototype.hasOwnProperty.call(parsedItem, 'expiry') &&
      Object.prototype.hasOwnProperty.call(parsedItem, 'value')
    ) {
      if (now.getTime() > parsedItem.expiry) {
        localStorage.removeItem(key);
        return null;
      } else {
        return parsedItem.value;
      }
    } else {
      return parsedItem;
    }
  } catch (e) {
    // if it's not a JSON string, just return the item
    return item;
  }
};

export const removeLocalStorageItem = key => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
