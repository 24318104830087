import { Box, Stack, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import useFormatMessage from '@/hooks/useFormatMessage';

import { Button, Input, MainWrapper, Wrapper } from './InputCounter.style';

const InputCounter = ({
  value,
  onChange,
  onBlur,
  step,
  min,
  max,
  readOnly,
  disabled,
  inputRef,
  name,
  'data-cy': dataCy,
  label,
  tooltipTitleMin,
  tooltipTitleMax,
  variant,
  sx,
}) => {
  const textInputRef = useRef(null);
  const formatMessage = useFormatMessage();

  const handleChange = e => {
    const { valueAsNumber } = e.target;

    if (
      valueAsNumber < min ||
      valueAsNumber > max ||
      Number.isNaN(valueAsNumber)
    ) {
      return;
    }
    onChange(valueAsNumber);
  };

  const increaseValue = () => {
    if (readOnly || disabled) {
      return;
    }
    const { valueAsNumber } = textInputRef.current;

    if (valueAsNumber < max) {
      const changedValue = valueAsNumber + step;
      const value = changedValue >= max ? max : changedValue;
      onChange(value);
    }
  };

  const decreaseValue = () => {
    if (readOnly || disabled) {
      return;
    }
    const { valueAsNumber } = textInputRef.current;

    if (valueAsNumber > min) {
      const changedValue = valueAsNumber - step;
      const value = changedValue <= min ? min : changedValue;
      onChange(value);
    }
  };

  const handleInputClick = () => {
    textInputRef.current.select();
  };

  return (
    <MainWrapper data-cy={dataCy || 'InputCounter'} variant={variant} sx={sx}>
      {label && (
        <Typography variant="body2" mr={1.5} component="label" htmlFor={name}>
          {label}
        </Typography>
      )}
      <Wrapper
        elevation={1}
        {...(variant === 'large' && { variant: 'outlined', elevation: 0 })}>
        <Tooltip title={value === min ? tooltipTitleMin : ''}>
          <Button
            onClick={decreaseValue}
            aria-label={formatMessage('minus_button')}
            type="button"
            data-cy="button: counterDecrease">
            <SvgIcon
              name="minus"
              color={value > min ? 'primary' : 'disabled'}
            />
          </Button>
        </Tooltip>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          position="relative"
          minWidth={24}>
          <Box component="span" sx={{ px: 1 }} aria-hidden="true">
            {value}
          </Box>
          <Input
            id={name}
            name={name}
            onChange={handleChange}
            onClick={handleInputClick}
            onBlur={onBlur}
            type="number"
            inputProps={{
              inputMode: 'numeric',
              min,
              max,
              step,
              'data-cy': 'input: counter',
            }}
            value={value}
            inputRef={e => {
              if (inputRef) {
                if (typeof inputRef === 'function') {
                  inputRef(e);
                } else {
                  inputRef.current = e;
                }
              }
              textInputRef.current = e;
            }}
            disabled={disabled}
            readOnly={readOnly}
          />
        </Stack>
        <Tooltip title={value === max ? tooltipTitleMax : ''}>
          <Button
            onClick={increaseValue}
            aria-label={formatMessage('plus_button')}
            type="button"
            data-cy="button: counterIncrease">
            <SvgIcon name="plus" color={value < max ? 'primary' : 'disabled'} />
          </Button>
        </Tooltip>
      </Wrapper>
    </MainWrapper>
  );
};

InputCounter.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  'data-cy': PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  tooltipTitleMin: PropTypes.string,
  tooltipTitleMax: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'large']),
};

InputCounter.defaultProps = {
  name: null,
  value: 0,
  step: 1,
  min: 0,
  max: 100000,
  onChange: () => {},
  onBlur: () => {},
  readOnly: false,
  disabled: false,
  'data-cy': '',
  label: '',
  inputRef: null,
  tooltipTitleMin: '',
  tooltipTitleMax: '',
  variant: 'small',
};

export default InputCounter;
