import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  Box,
  Paper,
  Stack,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import getConfig from '@/configs/global';
import { downloadFile } from '@/helpers/downloadFile';
import useFormatMessage from '@/hooks/useFormatMessage';

import { attributesPropTypes, datasheetPropTypes } from '../propTypes';
import {
  Chip,
  DataTableCell,
  DownloadButton,
  Table,
  TableCell,
  TableRow,
  ViewPDFButton,
} from './TechnicalData.style';

const {
  publicRuntimeConfig: { FEAT_FLAG_PRODUCT_DETAILS_DATASHEETS },
} = getConfig();

const TechnicalData = ({ attributes, datasheets, productSample }) => {
  const formatMessage = useFormatMessage();

  const openMultipleDatasheets = () => {
    // TODO: when opening multiple datasheets, only the first one is open. The rest is blocked by the browser.
    datasheets?.forEach(datasheet => {
      window.open(datasheet.url, '_blank');
    });
  };

  const downloadMultipleDatasheets = () => {
    datasheets?.forEach(datasheet => {
      downloadFile(datasheet.url, datasheet.name);
    });
  };

  return (
    <Box maxWidth={747} mx="auto">
      {Boolean(datasheets?.length || attributes?.length) && (
        <>
          <Chip
            label={formatMessage('product_details_page_technical_data_chip')}
            variant="filled"
            color="primary"
          />
          <TableContainer component={Paper} elevation={0} sx={{ mt: 4, mb: 5 }}>
            <Table>
              <TableBody>
                {FEAT_FLAG_PRODUCT_DETAILS_DATASHEETS &&
                  datasheets?.length > 0 && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                          gap={1}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}>
                            {productSample && (
                              <>
                                <InfoIcon color="primary" fontSize="small" />
                                <Typography
                                  color="primary"
                                  variant="body1Redesign"
                                  component="div"
                                  sx={{
                                    fontWeight: theme =>
                                      theme.typography.fontWeightSemiBold,
                                  }}>
                                  {formatMessage(
                                    'product_details_page_technical_data_sample_name',
                                    { name: productSample }
                                  )}
                                </Typography>
                              </>
                            )}
                          </Stack>
                          <ViewPDFButton
                            color="primary"
                            endIcon={
                              <ReceiptIcon
                                fontSize="small"
                                sx={{ mb: '2px' }}
                              />
                            }
                            onClick={openMultipleDatasheets}>
                            {formatMessage(
                              'product_details_page_view_datasheet_button'
                            )}
                          </ViewPDFButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                {attributes?.map(({ key, value }, index) => (
                  <TableRow key={index}>
                    <DataTableCell>{key}</DataTableCell>
                    <DataTableCell>{value}</DataTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {FEAT_FLAG_PRODUCT_DETAILS_DATASHEETS && datasheets?.length > 0 && (
            <Stack alignItems="center" mb={8}>
              <DownloadButton
                color="primary"
                size="extraLarge"
                fullWidth
                sx={{ maxWidth: 323 }}
                startIcon={<ArrowDownwardIcon fontSize="small" />}
                onClick={downloadMultipleDatasheets}>
                {formatMessage(
                  'product_details_page_download_datasheet_button'
                )}
              </DownloadButton>
            </Stack>
          )}
        </>
      )}
      <Box mt={8} mb={10}>
        <Typography
          color="text.secondary"
          variant="captionRedesign"
          component="p">
          {formatMessage('product_details_page_asterisk_text')}
        </Typography>
      </Box>
    </Box>
  );
};

TechnicalData.propTypes = {
  attributes: attributesPropTypes,
  datasheets: datasheetPropTypes,
  productSample: PropTypes.string,
};

export default TechnicalData;
