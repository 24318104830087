import { Paper, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import useFormatMessage from '@/hooks/useFormatMessage';

const Description = ({ description }) => {
  const formatMessage = useFormatMessage();

  if (!description) {
    return null;
  }

  return (
    <Paper elevation={0}>
      <Stack
        spacing={{ xs: 2, md: 7 }}
        direction={{ xs: 'column', md: 'row' }}
        px={2.5}
        py={2}>
        <Typography variant="h4Redesign" component="h2" color="primary.dark">
          {formatMessage('product_details_page_description_title')}
        </Typography>
        <Typography
          variant="body1Redesign"
          component="p"
          color="text.secondary"
          sx={{ whiteSpace: 'pre-line' }}>
          {description}
        </Typography>
      </Stack>
    </Paper>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

export default Description;
