import { alpha, styled } from '@mui/material/styles';

const DOT_SIZE = 8;

export const Dot = styled('div', {
  shouldForwardProp: prop => !['active', 'dotColor'].includes(prop),
})(({ theme, active, dotColor }) => ({
  backgroundColor: alpha(dotColor || theme.palette.common.blue, 0.3),
  width: DOT_SIZE,
  height: DOT_SIZE,
  borderRadius: DOT_SIZE / 2,
  transition: 'width 0.3s, background-color 0.3s',
  ...(active && {
    width: DOT_SIZE * 2.5,
    backgroundColor: dotColor || theme.palette.common.blue,
  }),
}));
