import { styled } from '@mui/material/styles';

export const Carousel = styled('div')(() => ({
  overflow: 'hidden',
}));

export const InnerWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'fullWidth',
})(({ fullWidth }) => ({
  position: 'relative',
  overflow: 'hidden',

  ...(!fullWidth && {
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      width: '16%',
      backgroundImage:
        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.50) 49.65%, #FFF 90%)',
    },
  }),
}));

export const Inner = styled('div')(() => ({
  transition: 'transform 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'row',
}));

export const Slide = styled('div', {
  shouldForwardProp: prop => prop !== 'url',
})(({ url }) => ({
  borderRadius: 15,
  height: 430,
  backgroundImage: `url("${url}")`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));
