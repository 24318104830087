import {
  Chip as MuiChip,
  chipClasses,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ButtonBase from '@/components/ui/Button/Button';

export const Chip = styled(MuiChip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    ...theme.typography.buttonSmallRedesign,
  },
}));

export const Table = styled(MuiTable)(() => ({}));

export const TableRow = styled(MuiTableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  border: 'none',
  color: theme.palette.text.secondary,
  ...theme.typography.body1Redesign,
}));

export const DataTableCell = styled(TableCell)(() => ({
  '&:first-child': {
    width: '100%',
  },

  '&:last-child': {
    whiteSpace: 'nowrap',
  },
}));

export const ViewPDFButton = styled(ButtonBase)(({ theme }) => ({
  textTransform: 'uppercase',
  ...theme.typography.buttonMediumRedesign,
  minWidth: 215,
}));

export const DownloadButton = styled(ButtonBase)(({ theme }) => ({
  textTransform: 'uppercase',
  ...theme.typography.buttonSmallRedesign,
}));
