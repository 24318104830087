import cloneDeep from 'lodash.clonedeep';
import setObjectPropValue from 'lodash.set';
import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';

import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@/helpers/localStorage';
import { validateVoucher } from '@/helpers/validators';

export const OrderDetailsFormContext = createContext();

export const initialData = {
  gender: 'male',
  forename: '',
  surname: '',
  phone: {
    countryCode: 'DE',
    number: '',
  },
  email: '',
  company: {
    name: '',
    crefo: null,
  },
  addNonCrefoCompany: false,
  nonCrefoCompanyName: '',
  nonCrefoCompanyAddress: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  deliveryLocation: {
    street: '',
    postalCode: '',
    city: '',
    country: { label: 'Deutschland', value: 'Deutschland' },
  },
  delivery: true,
  technicianContactSameAsPrimary: false,
  technicianGender: 'male',
  technicianForename: '',
  technicianSurname: '',
  technicianPhone: {
    countryCode: 'DE',
    number: '',
  },
  voucher: '',
  consultationRequired: false,
  tos: false,
  marketingConsent: false,
};

export const OrderDetailsFormProvider = ({ children }) => {
  const [orderDetailsData, setOrderDetailsData] = useState(initialData);
  const [dataHydrated, setHydrated] = useState(false);
  const {
    query: { voucher: voucherCodeQuery },
  } = useRouter();

  const resetOrderDetailsData = () => setOrderDetailsData(initialData);

  const saveOrderdDetailsDataInLocalStorage = data => {
    setLocalStorageItem(LOCAL_STORAGE_KEYS.ORDER_DETAILS, data);
  };

  const saveOrderDetailsData = () => {
    setOrderDetailsData(prev => {
      const data = {
        ...prev,
        consultationRequired: false,
        voucher: '',
        tos: false,
        marketingConsent: false,
      };
      saveOrderdDetailsDataInLocalStorage(data);
      return data;
    });
  };

  const updateOrderDetailsData = (fieldName, value) => {
    // fieldName can use dot notation for example phone.number
    setOrderDetailsData(prev => {
      const updatedPrev = cloneDeep(prev);
      setObjectPropValue(updatedPrev, fieldName, value);
      return updatedPrev;
    });
  };

  useEffect(() => {
    const hydrateOrderDetailsData = async () => {
      const savedOrderDetailsData = getLocalStorageItem(
        LOCAL_STORAGE_KEYS.ORDER_DETAILS
      );
      const voucher =
        voucherCodeQuery ||
        getLocalStorageItem(LOCAL_STORAGE_KEYS.VOUCHER) ||
        '';
      let validVoucher = false;

      if (voucher) {
        try {
          validVoucher = await validateVoucher(voucher);
        } catch (error) {
          console.error('Error validating voucher:', error);
        }
      }

      setOrderDetailsData(prev => {
        return {
          ...(savedOrderDetailsData ?? prev),
          voucher: validVoucher ? voucher : '',
        };
      });

      setHydrated(true);
    };

    hydrateOrderDetailsData();
  }, []);

  return (
    <OrderDetailsFormContext.Provider
      value={{
        orderDetailsData,
        updateOrderDetailsData,
        resetOrderDetailsData,
        saveOrderDetailsData,
        saveOrderdDetailsDataInLocalStorage,
        orderDetailsDataHydrated: dataHydrated,
      }}>
      {children}
    </OrderDetailsFormContext.Provider>
  );
};
