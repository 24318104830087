import axios from 'axios';

export const downloadFile = async (url, fileName) => {
  if (!url) {
    return;
  }

  try {
    const response = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
    });

    const href = window.URL.createObjectURL(response.data);
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download = fileName;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  } catch (error) {
    console.log(error);
  }
};
