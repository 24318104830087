import PropTypes from 'prop-types';

export const datasheetPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })
);

export const attributesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })
);

export const imagesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    url: PropTypes.string.isRequired,
  })
);

export const productDetailsDataPropTypes = PropTypes.shape({
  product_group_name: PropTypes.string.isRequired,
  product_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  salesforce_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  automated_offer: PropTypes.bool,
  min_lead_time: PropTypes.number,
  family_name: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rental_price: PropTypes.number,
  rental_price_basis: PropTypes.string,
  product_description: PropTypes.string,
  product_sample: PropTypes.string,
  invoice_basis: PropTypes.string,
  images: imagesPropTypes,
  product_reference_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  datasheets: datasheetPropTypes,
  attributes: attributesPropTypes,
});
