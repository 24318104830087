import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const AvailabilityInfo = styled(Typography, {
  shouldForwardProp: prop => prop !== 'availableInstantly',
})(({ theme, availableInstantly }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  lineHeight: 1,
  letterSpacing: '0.26px',
  whiteSpace: 'nowrap',

  '&:before': {
    content: '""',
    display: 'inline-block',
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: availableInstantly
      ? '#66bb6a'
      : theme.palette.secondary.main,
    marginRight: theme.spacing(0.75),
    position: 'relative',
    top: 3,
  },
}));
