import '../styles/fonts.css';
import '../styles/global.css';
import '../styles/slick.css';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import * as Sentry from '@sentry/nextjs';
import Script from 'next/script';
import React, { useEffect } from 'react';

import PageHead from '@/components/PageHead/PageHead';
import getConfig from '@/configs/global';
import { AuthProvider } from '@/context/authContext';
import { BasketProvider } from '@/context/basketContext/basketContext';
import { BasketPreviewProvider } from '@/context/basketPreviewContext';
import { CraneWizardProvider } from '@/context/craneWizardContext';
import { DirectRequestDialogProvider } from '@/context/directRequestDialogContext';
import { DirectRequestFormProvider } from '@/context/directRequestFormContext';
import { GenericContactFormProvider } from '@/context/genericContactFormContext';
import { HeatingAssistantProvider } from '@/context/heatingAssistantContext';
import { IntlProvider } from '@/context/intlContext';
import { LocalizationProvider } from '@/context/localizationContext';
import { NavigationProvider } from '@/context/navigationContext';
import { OrderDetailsFormProvider } from '@/context/orderDetailsFormContext';
import { SourceProvider } from '@/context/sourceContext';
import { getParsedAcceptLangs } from '@/helpers/i18n';
import { fetchNavigation, fetchNewNavigation } from '@/helpers/navigation';
import { updatePhoneNumbers } from '@/helpers/utils';
import useSaveQueryParamsInLocalStorage from '@/hooks/useSaveQueryParamsInLocalStorage';
import theme from '@/styles/theme';

const {
  publicRuntimeConfig: {
    BASE_URL,
    ONETRUST_DOMAIN_SCRIPT,
    FEAT_FLAG_NEW_PAGE_HEADER,
  },
} = getConfig();

function KlickrentApp(props) {
  const {
    err,
    Component,
    pageProps,
    canonicalUrl,
    navigation,
    acceptLanguages,
    ...restProps
  } = props;

  useSaveQueryParamsInLocalStorage();

  useEffect(() => {
    updatePhoneNumbers();
  }, []);

  return (
    <IntlProvider acceptLanguages={acceptLanguages}>
      <AppCacheProvider {...{ err, Component, pageProps, ...restProps }}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <SourceProvider>
              <NavigationProvider navigation={navigation}>
                <BasketProvider>
                  <BasketPreviewProvider>
                    <DirectRequestFormProvider>
                      <DirectRequestDialogProvider>
                        <OrderDetailsFormProvider>
                          <GenericContactFormProvider>
                            <HeatingAssistantProvider>
                              <CraneWizardProvider>
                                <LocalizationProvider>
                                  <PageHead
                                    canonicalUrl={canonicalUrl}
                                    baseUrl={BASE_URL}
                                  />
                                  {/* OneTrust Cookie Consent Notice start */}
                                  <Script
                                    key="onetrust"
                                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                                    type="text/javascript"
                                    charSet="UTF-8"
                                    data-domain-script={ONETRUST_DOMAIN_SCRIPT}
                                  />
                                  <Script
                                    id="onetrust-cookie"
                                    strategy="lazyOnload">
                                    {'function OptanonWrapper() { }'}
                                  </Script>
                                  {/* OneTrust Cookie Consent Notice end */}
                                  <div id="overlay" />
                                  <CssBaseline />
                                  <Component {...pageProps} err={err} />
                                </LocalizationProvider>
                              </CraneWizardProvider>
                            </HeatingAssistantProvider>
                          </GenericContactFormProvider>
                        </OrderDetailsFormProvider>
                      </DirectRequestDialogProvider>
                    </DirectRequestFormProvider>
                  </BasketPreviewProvider>
                </BasketProvider>
              </NavigationProvider>
            </SourceProvider>
          </AuthProvider>
        </ThemeProvider>
      </AppCacheProvider>
    </IntlProvider>
  );
}

KlickrentApp.getInitialProps = async ({ Component, ctx }) => {
  let navigation;

  try {
    if (FEAT_FLAG_NEW_PAGE_HEADER) {
      navigation = await fetchNewNavigation();
    } else {
      navigation = await fetchNavigation();
    }
  } catch (err) {
    Sentry.captureException(err);
  }

  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  const canonicalUrl = BASE_URL + ctx.asPath.split('?')[0];

  return {
    navigation,
    pageProps,
    canonicalUrl,
    acceptLanguages: getParsedAcceptLangs(
      ctx.req?.headers?.['accept-language']
    ),
  };
};

export default KlickrentApp;
