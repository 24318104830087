import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import ProductDetailsPage from '@/components/ProductDetailsPage/ProductDetailsPage';
import getConfig from '@/configs/global';
import dametisApi from '@/helpers/dametisApi';
import { pageView } from '@/helpers/dataLayer';
import { generateHreflangLinks } from '@/helpers/hreflang';
import extractSeoData from '@/helpers/seo';
import strapiApi from '@/helpers/strapi/api';
import {
  generateBreadcrumbStructuredData,
  generateFaqStructuredData,
  generateLogoStructuredData,
} from '@/helpers/strapi/structuredData';
import { useSourceContext } from '@/hooks/useSourceContext';
import DefaultLayout from '@/layouts/default';
import Page from '@/templates/Page/Page';

const {
  publicRuntimeConfig: { BASE_URL, FEAT_FLAG_PRODUCT_DETAILS_PAGE },
} = getConfig();

/**
 * It's heavily encouraged to benefit from optional chaining in this file,
 * given that data from Strapi might be provided corrupted or deficient.
 * In such scenarios, optional chaining allows us to have non-broken components.
 */
const DynamicPage = ({ page, breadcrumbs, refererPathname }) => {
  const { asPath, query } = useRouter();
  const seoData = page?.attributes?.seo;
  const productDetailsPage = page?.productDetailsPage;
  const {
    keywords,
    ogImageUrl,
    twitterTitle,
    twitterDescription,
    twitterImageUrl,
  } = extractSeoData(seoData);
  const [pathname] = asPath.split('?');
  const { setLeadReference } = useSourceContext();
  const ogUrl = `${BASE_URL}${pathname}`;

  useEffect(() => {
    if (page?.attributes?.title) {
      pageView({ title: page.attributes.title });
    }
    setLeadReference(
      productDetailsPage
        ? `${pathname}?productReferenceId=${query.productReferenceId}`
        : pathname
    );
  }, [pathname, page?.attributes?.title, setLeadReference, productDetailsPage]);

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <Head>
        <title>{seoData?.metaTitle || page.attributes.title}</title>
        <meta
          key="description"
          name="description"
          content={seoData?.metaDescription || page.attributes.description}
        />
        {keywords && <meta name="keywords" content={keywords}></meta>}
        <meta
          property="og:title"
          content={seoData?.metaTitle || page.attributes.title}
          key="og:title"
        />
        <meta
          property="og:description"
          content={seoData?.metaDescription || page.attributes.description}
          key="og:description"
        />
        {ogImageUrl && (
          <>
            <meta property="og:image" content={ogImageUrl} key="og:image" />
            <meta
              property="og:image:secure_url"
              content={ogImageUrl}
              key="og:image:secure_url"
            />
          </>
        )}
        <meta property="og:url" content={ogUrl}></meta>
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:locale" content="de_DE" key="og:locale" />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        {twitterTitle && (
          <meta
            name="twitter:title"
            content={twitterTitle}
            key="twitter:title"
          />
        )}
        {twitterDescription && (
          <meta
            name="twitter:description"
            content={twitterDescription}
            key="twitter:description"
          />
        )}
        {twitterImageUrl && (
          <meta
            name="twitter:image"
            content={twitterImageUrl}
            key="twitter:image"
          />
        )}
        {seoData?.structuredData && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(seoData?.structuredData),
            }}
            key="page-jsonld"
          />
        )}
        {generateBreadcrumbStructuredData(breadcrumbs)}
        {generateFaqStructuredData(page)}
        {generateLogoStructuredData(pathname)}
        {pathname === '/' && generateHreflangLinks()}
      </Head>
      {productDetailsPage ? (
        <ProductDetailsPage data={page.productDetailsData} />
      ) : (
        <Page page={page} refererPathname={refererPathname} />
      )}
    </DefaultLayout>
  );
};

DynamicPage.propTypes = {
  page: PropTypes.object,
};

DynamicPage.defaultProps = {
  page: undefined,
};

const getProductDetailsPageProps = async ({
  productReferenceId,
  pathname,
  resolvedUrl,
}) => {
  try {
    const productData = (await dametisApi.getProductDetails(productReferenceId))
      .data;
    const pageData = (await strapiApi.getPageByPathname(pathname)).data.data;
    const dametisCatalogComponents = pageData.attributes.body.filter(
      component => component?.__component === 'catalogs.dametis-product-catalog'
    );
    const groupIdMatch = dametisCatalogComponents.some(component => {
      const dametisId = component?.productGroup?.data?.attributes?.dametisId;
      const productGroupId = productData?.product_group_id;
      return dametisId?.toString() === productGroupId?.toString();
    });

    if (!groupIdMatch) {
      return {
        notFound: true,
      };
    }

    const productDetailsBreadcrumb = {
      id: uuidv4(),
      title: productData.name,
      metadata: {
        id: uuidv4(),
        pathname: resolvedUrl,
      },
    };

    let breadcrumbsData = null;

    try {
      breadcrumbsData = (await strapiApi.getBreadcrumbsByPathname(pathname))
        .data.data;
    } catch {
      // ignore error - render page without breadcrumbs
    }

    return {
      props: {
        page: {
          productDetailsPage: true,
          productDetailsData: {
            ...productData,
            invoice_basis: productData.rental_price_basis,
            product_reference_id: productReferenceId,
          },
          attributes: {
            title: productData.name,
          },
        },
        breadcrumbs: breadcrumbsData
          ? [...breadcrumbsData, productDetailsBreadcrumb]
          : null,
      },
    };
  } catch (error) {
    return { notFound: true };
  }
};

export async function getServerSideProps({ res, req, resolvedUrl, query }) {
  const [pathname] = resolvedUrl.split('?');
  const { productReferenceId } = query || {};

  if (productReferenceId && FEAT_FLAG_PRODUCT_DETAILS_PAGE) {
    return await getProductDetailsPageProps({
      productReferenceId,
      pathname,
      resolvedUrl,
    });
  } else {
    const pageData = (await strapiApi.getPageByPathname(pathname)).data.data;
    const statusCode = pageData?.id ? 200 : 404;
    const referer = req.headers?.referer;
    let refererPathname;

    let breadcrumbsData = null;
    if (statusCode === 200) {
      breadcrumbsData = (await strapiApi.getBreadcrumbsByPathname(pathname))
        .data.data;
    }

    /**
     * `res` is available only on the server-side where `statusCode` is meaningful
     */
    if (res) {
      res.statusCode = statusCode;
    }

    if (referer) {
      try {
        refererPathname = new URL(referer).pathname;
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    return {
      props: {
        page: pageData,
        breadcrumbs: breadcrumbsData,
        refererPathname: refererPathname || null,
      },
      notFound: statusCode === 404,
    };
  }
}

export default DynamicPage;
