import MuiArrow from '@mui/icons-material/ArrowDownward';
import { CardActionArea, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import KlickrentLogo from '@/assets/logo.svg';

export const MainImageWrapper = styled(Paper)(() => ({
  position: 'relative',
  aspectRatio: 1.1,
}));

export const ActionArea = styled(CardActionArea)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const ThumbnailImageWrapper = styled(Paper, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  position: 'relative',
  aspectRatio: '1/1',
  backgroundColor: theme.palette.common.white,
  flexShrink: 0,
  flexBasis: `calc(20% - (4 * ${theme.spacing(1.5)} / 5))`,
  ...(!active && {
    borderColor: 'transparent',
  }),
}));

export const ArrowsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 18,
  height: 18,
  top: theme.spacing(1),
  right: theme.spacing(1.25),
  zIndex: 1,
}));

export const ImagePlaceholder = styled(KlickrentLogo)(() => ({
  width: '30%',
  height: '30%',
  opacity: 0.2,
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  top: '50%',
  left: '50%',
}));

export const Arrow = styled(MuiArrow, {
  shouldForwardProp: prop => prop !== 'top',
})(({ top, theme }) => ({
  fontSize: 10,
  position: 'absolute',
  color: theme.palette.grayscale[700],
  ...(top
    ? { top: 0, left: 0, transform: 'rotate(135deg)' }
    : { bottom: 0, right: 0, transform: 'rotate(-45deg)' }),
}));
