import { useTheme } from '@emotion/react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

import { Dot } from './SlidesIndicator.style';

const SlidesIndicator = ({
  sx,
  numberOfSlides,
  activeIndex,
  dotColor,
  isDotActive,
  className,
}) => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={0.5}
      sx={sx}
      className={className}>
      {[...new Array(numberOfSlides)].map((_, index) => (
        <Dot
          active={isDotActive?.(index) || index === activeIndex}
          key={index}
          dotColor={typeof dotColor === 'function' ? dotColor(theme) : dotColor}
        />
      ))}
    </Stack>
  );
};

SlidesIndicator.propTypes = {
  numberOfSlides: PropTypes.number.isRequired,
  activeIndex: PropTypes.number,
  isDotActive: PropTypes.func,
  sx: PropTypes.object,
  dotColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
};

export default SlidesIndicator;
