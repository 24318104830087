import { Box } from '@mui/material';

import PageMargins from '@/components/ui/PageMargins/PageMargins';

import Description from './Description/Description';
import MainInfo from './MainInfo/MainInfo';
import { productDetailsDataPropTypes } from './propTypes';
import RequestForm from './RequestForm/RequestForm';
import TechnicalData from './TechnicalData/TechnicalData';

const ProductDetailsPage = ({ data }) => {
  return (
    <PageMargins>
      <Box mb={8.5} mt={6}>
        <MainInfo
          name={data?.name}
          familyName={data?.family_name}
          price={data?.rental_price}
          priceBasis={data?.rental_price_basis}
          images={data?.images}
          form={<RequestForm data={data} />}
        />
      </Box>
      {data?.product_description && (
        <Box mb={12.5}>
          <Description description={data.product_description} />
        </Box>
      )}
      <Box mb={12.5}>
        <TechnicalData
          attributes={data?.attributes}
          datasheets={data?.datasheets}
          productSample={data?.product_sample}
        />
      </Box>
    </PageMargins>
  );
};

ProductDetailsPage.propTypes = {
  data: productDetailsDataPropTypes,
};

export default ProductDetailsPage;
